.container{
    width: 300px;
    height: max-content;
    margin: auto;
    background-color: white;
    padding: 20px 15px;
    margin-top: 120px;
    border-radius: 7px;
    font-family: sans-serif;
    box-shadow: 5px 6px rgba(0, 0, 0, 0.7);
}

.logo{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 200px;
    margin-top: 20px;
    margin-bottom: 10px;
}

h1{
    margin: 0;
    text-align: center;
    margin-bottom: 10px;
    /* color: rgb(209,209,209); */
}

.login-form{
    padding: 5px;
}

.form-field{
    display: inline-block;
    padding: 5px;
    margin-top: 20px;
    width: 280px;
    height: 30px;
    font-family: sans-serif;
    font-size: large;
}

.form-error{
    display: block;
    color: rgb(237, 67, 55);
    font-size: 0.9em;
    font-weight: 600;
}

.button{
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 5px 10px;
    background-color: rgb(31, 71, 131);
    /* background-color: rgb(209,209,209); */
    color: white;
    font-weight: 800;
    font-family: sans-serif;
    font-size: large;
    width: 295px;
    height: 40px;
}

body{
    background-color: rgb(209,209,209);
}