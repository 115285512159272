.Topbar{
    height: 100px;
    background-color: white;
}

.logoTopbar{
    width: 150px;
    padding-top: 10px;
    padding-left: 10px;
}

.Ola{
    float: right;
    font-size: 1.2rem;
    padding-top: 40px;
    padding-right: 40px;
}