.Navbar{
    background-color: white;
    width: 20%;
    max-width: 300px;
    float: left;
}

li {
    padding: 10px;
    list-style-type: none;
    font-size: 1.2rem;
    margin-left: -30px;
}

/* link que ainda não foi visitado */
a:link {
    color: black;
}

/* link que foi visitado */
a:visited {
    color: gray;
}

a:hover {
    color: black;
}

/* link selecionado */
a:active {
    color: black;
}